<template>
  <div>
    <TopNavigation />
    <div class="pa-10">
      <h2 class="refundTitle justify-center">Refunds & Cancellations</h2>
      <div class="refundMessage mt-10">
        <p>Our policy lasts 3 days. If 3 days have gone by since your purchase, unfortunately we can’t offer you a refund.</p>

        <div>
          <p class="refundInfoTitle my-2">Replacements(not applicable)</p>
          <p>All our products(Fruits & Vegetables) are Perishable goods and cannot be returned.</p>
        </div>

        <div>
          <p class="refundInfoTitle my-2">Refunds (if applicable)</p>
          <p>Once you submit photographs of damage or missing items, we will send you an email to notify you that we have received your request. We will also notify you of the approval or rejection of your refund.</p>
          <p>If you are approved, then your refund will be processed, and a credit will automatically be applied to your credit card or original method of payment, within 3 days.</p>
        </div>

        <div>
          <p class="refundInfoTitle my-2">Late or missing refunds (if applicable)</p>
          <p>If you haven’t received a refund yet, first check your bank account again.</p>
          <p>Then contact your credit card company, it may take some time before your refund is officially posted.</p>
          <p>Next contact your bank. There is often some processing time before a refund is posted.</p>
          <p>If you’ve done all of this and you still have not received your refund yet, please contact us at support@kiraak.co.</p>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import TopNavigation from './TopNavigation.vue'
export default {
  name: 'RefundPolicy',
  components: {
    TopNavigation
  },
  data () {
    return {
    }
  },
  methods: {

  },
  mounted () {

  }
}
</script>
<style scoped>
.refundMessage {
  color: #fdfaf1;
}
.refundTitle {
  color: #fdfaf1;
  font-size: 30px;
}
.refundInfoTitle {
  font-weight: 900;
}
</style>
